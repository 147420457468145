import React from "react";
import { Col, Row } from "react-bootstrap";

const SmallCard = ({ icon, title, subTitle }) => {
  return (
    <>
      <Col xs={6} style={{ marginBottom: "10px" }}>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col xs={4} style={{ fontSize: "50px" }}>
                {icon}
              </Col>
              <Col xs={8}>
                <br />
                <h6>
                  {title}
                  <br />
                  <small>{subTitle}</small>
                </h6>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
};

export { SmallCard };
