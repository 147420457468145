import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ link, children, icon }) => {
  return (
    <>
      <li class="nav-item">
        <Link class="nav-link" to={link} style={{ color: "#ffffff" }}>
          {icon} {children}
        </Link>
      </li>
    </>
  );
};

export { NavItem };
