import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaSearch, FaBlog } from "react-icons/fa";
import { IoIosContact } from "react-icons/io";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCodeSlash, BsFillBriefcaseFill } from "react-icons/bs";

import Logo from "../../kesho-logo-light.png";
import { NavItem } from "./NavItem";

const Items = [
  {},
  {
    link: "/about",
    children: "About",
    icon: AiOutlineInfoCircle(),
  },
  {
    link: "/services",
    children: "Services",
    icon: BsFillBriefcaseFill(),
  },
  {
    link: "/portfolio",
    children: "Portfolio",
    icon: BsCodeSlash(),
  },
  {
    link: "/blog",
    children: "Blog",
    icon: FaBlog(),
  },
  {
    link: "/contact",
    children: "Contact",
    icon: IoIosContact(),
  },
];
const Nav = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <ul className="nav mr-auto">
            <li class="nav-item">
              <Link class="nav-link" to="/">
                <img src={Logo} className="img-logo" alt="" />
              </Link>
            </li>
          </ul>
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" style={{ color: "#ffffff" }}>
                <FaTwitter />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/" style={{ color: "#ffffff" }}>
                <FaSearch />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <ul class="nav ">
            {Items.map((item, key) => (
              <NavItem
                link={item.link}
                children={item.children}
                icon={item.icon}
                key={key}
              />
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export { Nav };
