import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillAndroid, AiFillWindows } from "react-icons/ai";
import { SiIos, SiReact } from "react-icons/si";

import { Modal, SmallCard } from "../../components";

const Items = [
  {
    icon: AiFillAndroid(),
    title: "Android",
    subTitle: "For all devices",
  },
  {
    icon: SiIos(),
    title: "iOS",
    subTitle: "For all devices",
  },
  {
    icon: AiFillWindows(),
    title: "Windows",
    subTitle: "For all devices",
  },
  {
    icon: SiReact(),
    title: "Web",
    subTitle: "Fully Responsive",
  },
];
const Home = () => {
  return (
    <>
      <Modal modalTitle="Request for Quote" modalId="quoteModal">
        ...
      </Modal>
      <section class="jumbotron">
        <div className="container">
          <Row>
            <Col xs={6}>
              <h2>&lt;Responsive Design /&gt;</h2>
              <br />
              <Row>
                {Items.map((item, key) => (
                  <SmallCard
                    icon={item.icon}
                    title={item.title}
                    subTitle={item.subTitle}
                    key={key}
                  />
                ))}
              </Row>
              <Row>
                <Col xs={12}>
                  <button
                    type="button"
                    class="btn btn-info btn-block"
                    data-toggle="modal"
                    data-target="#quoteModal"
                  >
                    Request for Quote
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={6}></Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export { Home };
